import Vue from 'vue';
import VueRouter from 'vue-router';

import vuetify from '@/plugins/vuetify';

import * as ROUTES from '@/constants/ROUTES';

import {
  layoutRoute, viewRoute, redirectRoute, requireAuthenticated, requireUnauthenticated,
} from '../utils/router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,

  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    } else if (to.name === from.name) {
      scrollTo = document.documentElement.scrollTop;
    }

    return vuetify.framework.goTo(scrollTo);
  },

  routes: [
    layoutRoute(ROUTES.ROUTE_SIGN_IN.path, 'base', [
      viewRoute({
        name: ROUTES.ROUTE_SIGN_IN.name,
        view: 'sign-in',
      }, requireUnauthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_HOME.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_HOME.name,
        view: 'home',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_CADASTRO_EMPRESA.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_CADASTRO_EMPRESA.name,
        view: 'cadastroempresa',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_CADASTRO_CLIENTE.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_CADASTRO_CLIENTE.name,
        view: 'cadastrocliente',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_CADASTRO_USUARIO.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_CADASTRO_USUARIO.name,
        view: 'cadastrousuario',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_LISTA_EMPRESA.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_LISTA_EMPRESA.name,
        view: 'listaempresa',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_LISTA_CLIENTE.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_LISTA_CLIENTE.name,
        view: 'listacliente',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_LISTA_USUARIO.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_LISTA_USUARIO.name,
        view: 'listausuario',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_TRIBUTO_PRODUTO.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_TRIBUTO_PRODUTO.name,
        view: 'tributacao',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    layoutRoute(ROUTES.ROUTE_TRIBUTO_EMPRESA.path, 'default', [
      viewRoute({
        name: ROUTES.ROUTE_TRIBUTO_EMPRESA.name,
        view: 'tributacaoempresa',
        appBar: 'AppBar',
      }, requireAuthenticated),
    ]),

    redirectRoute(ROUTES.ROUTE_HOME),
  ],
});

export default router;
