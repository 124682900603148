import apiclienteweb from '@/services/api-recebimentoweb';

export default {
  namespaced: true,

  state: {
    list: [],
  },

  getters: {
    list: (state) => state.list,
  },

  // NUNCA ALTERAR state DIRETAMENTE EM ACTIONS
  actions: {
    async find(_, {
      where, ...params
    } = {}) {
      const [{ data }] = await Promise.all([
        apiclienteweb.get('cliente/app', { params: { ...where, ...params } }),
      ]);

      return data;
    },

    async findByEmpresa(_, { idempresa }) {
      const [{ data }] = await Promise.all([
        apiclienteweb.get(`cliente/empresa/${idempresa}`),
      ]);

      return data;
    },

    async findByIdNuvem(_, { id }) {
      const [{ data }] = await Promise.all([
        apiclienteweb.get(`cliente/${id}`),
      ]);

      return data;
    },

    async update(_, body) {
      const { data } = await apiclienteweb.put(`cliente/app/${body.id}`, body);
      return data;
    },

    async create(_, body) {
      console.log(body);
      const { data } = await apiclienteweb.post('cliente/app', body);
      return data;
    },

  },

  // APENAS O MUTATION FAZ ALTERAÇÕES NO STATE
  mutations: {
    SET_LIST(state, list) {
      state.list = list ?? [];
    },
  },
};
