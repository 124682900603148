import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import users from './modules/users';
import empresa from './modules/empresa';
import documentos from './modules/documentos';
import cliente from './modules/cliente';
import divergenciasdocumentos from './modules/divergencias-documentos';
import documentoszip from './modules/documentoszip';

Vue.use(Vuex);

export default new Vuex.Store({
  actions: {
    async $_setup({ dispatch }) {
      await dispatch('auth/$_setup');
    },
  },

  modules: {
    auth,
    users,
    empresa,
    documentos,
    cliente,
    divergenciasdocumentos,
    documentoszip,
  },
});
