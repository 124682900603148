export const ROUTE_HOME = {
  path: '/',
  name: 'home',
};

export const ROUTE_ABOUT = {
  path: '/about',
  name: 'about',
};

export const ROUTE_CONSULTA = {
  path: '/consulta',
  name: 'consulta',
};

export const ROUTE_CADASTRO = {
  path: '/cadastro',
  name: 'cadastro',
};

export const ROUTE_IMAGENS = {
  path: '/imagens',
  name: 'imagens',
};

export const ROUTE_CAMERA = {
  path: '/camera/:tipo:id:valor',
  name: 'camera',
};

export const ROUTE_SIGN_IN = {
  path: '/sing-in',
  name: 'sign-in',
};

export const ROUTE_CADASTRO_EMPRESA = {
  path: '/cadastroempresa',
  name: 'cadastroempresa',
};

export const ROUTE_CADASTRO_CLIENTE = {
  path: '/cadastrocliente',
  name: 'cadastrocliente',
};

export const ROUTE_CADASTRO_USUARIO = {
  path: '/cadastrousuario',
  name: 'cadastrousuario',
};

export const ROUTE_LISTA_EMPRESA = {
  path: '/listaempresa',
  name: 'listaempresa',
};

export const ROUTE_LISTA_CLIENTE = {
  path: '/listacliente',
  name: 'listacliente',
};

export const ROUTE_LISTA_USUARIO = {
  path: '/listausuario',
  name: 'listausuario',
};

export const ROUTE_TRIBUTO_PRODUTO = {
  path: '/tributoproduto',
  name: 'tributoproduto',
};

export const ROUTE_TRIBUTO_EMPRESA = {
  path: '/tributoempresa',
  name: 'tributoempresa',
};
