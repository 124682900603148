import apiempresaweb from '@/services/api-recebimentoweb';

export default {
  namespaced: true,

  state: {
    list: [],
  },

  getters: {
    list: (state) => state.list,
  },

  // NUNCA ALTERAR state DIRETAMENTE EM ACTIONS
  actions: {
    async find(_, {
      where, ...params
    } = {}) {
      const [{ data }] = await Promise.all([
        apiempresaweb.get('empresa', { params: { ...where, ...params } }),
      ]);

      return data;
    },

    async findByIdNuvem(_, { id }) {
      const [{ data }] = await Promise.all([
        apiempresaweb.get(`empresa/${id}`),
      ]);

      return data;
    },

    async update(_, body) {
      const { data } = await apiempresaweb.put(`empresa/app/${body.id}`, body);
      return data;
    },

    async create(_, body) {
      console.log(body);
      const { data } = await apiempresaweb.post('empresa/app', body);
      return data;
    },

    async findByIdUser(_, { id }) {
      const [{ data }] = await Promise.all([
        apiempresaweb.get(`empresa/user/${id}`),
      ]);

      return data;
    },
  },

  // APENAS O MUTATION FAZ ALTERAÇÕES NO STATE
  mutations: {
    SET_LIST(state, list) {
      state.list = list ?? [];
    },
  },
};
