import apidocumentosweb from '@/services/api-recebimentoweb';

export default {
  namespaced: true,

  state: {
    list: [],
  },

  getters: {
    list: (state) => state.list,
  },

  // NUNCA ALTERAR state DIRETAMENTE EM ACTIONS
  actions: {
    async find(_, {
      where, ...params
    } = {}) {
      const [{ data }] = await Promise.all([
        apidocumentosweb.get('documentos', { params: { ...where, ...params } }),
      ]);

      return data;
    },

    async findDadosArquivo(_, { params, options }) {
      const p = params;
      // ${p.offset}/${p.limit}
      const [{ data }] = await Promise.all([
        apidocumentosweb.get(`/documentos/dados/arquivo/${p.idempresa}/${p.idcliente}/${p.mes}/${p.tipo}`,
          { params: options }),
      ]);

      return data;
    },

    async findDadosMes(_, { params }) {
      const p = params;
      const [{ data }] = await Promise.all([
        apidocumentosweb.get(`/documentos/dados/mes/${p.idempresa}/${p.idcliente}`),
      ]);

      return data;
    },

    async findDadosTipo(_, { params }) {
      const p = params;
      const [{ data }] = await Promise.all([
        apidocumentosweb.get(`/documentos/dados/tipo/${p.idempresa}/${p.idcliente}/${p.mes}`),
      ]);

      return data;
    },

    async zipArquivos(_, { params }) {
      const p = params;
      const [{ data }] = await Promise.all([
        apidocumentosweb.get(`/documentos/zip/${p.idempresa}/${p.idcliente}/${p.mes}/${p.tipo}/${p.dataAtual}`),
      ]);

      return data;
    },

    async deletaZip(_, { params }) {
      const p = params;
      const [{ data }] = await Promise.all([
        apidocumentosweb.delete(`/documentos/zip/${p.idempresa}/${p.idcliente}/${p.mes}/${p.tipo}`),
      ]);

      return data;
    },
  },

  // APENAS O MUTATION FAZ ALTERAÇÕES NO STATE
  mutations: {
    SET_LIST(state, list) {
      state.list = list ?? [];
    },
  },
};
